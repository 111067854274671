body {
  margin: 0;
}

div {
  font-family: Rubik;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

@font-face {
  font-family: "Rubik";
  src: url("assets/font/Rubik-VariableFont_wght.ttf") format("truetype");

  font-style: normal;
}

button {
  font-family: Rubik;
}

.showTour:hover {
  cursor: pointer;
}

.MuiOutlinedInput-notchedOutline {
  border-width: 0px !important;
}

.MuiTooltip-tooltip {
  background-color: #ffffff !important;
  width: 500px;
  border: 1px solid #afb8cd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.class1 {
  background-color: rgb(235, 62, 103) !important;
  border-color: rgb(235, 62, 103) !important;
  border-radius: 20px !important;
  width: 100px !important;
}

.swal2-container {
  z-index: 999999 !important;
}

.reactour__popover {
  border-radius: 10px;
  background-color: #c4c4c4;
}

.css-hlw4e0-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: #e6e7f3 !important;
  height: 27px;
}
.description-attetions-map {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  padding-left: 16px !important;
  border-radius: 4px;
}

.color-picket-background:hover {
  cursor: pointer;
}

.css-hlw4e0-MuiButtonBase-root-MuiMenuItem-root {
  background-color: #e6e7f3 !important;
  height: 27px;
  margin-bottom: 4px !important;
  border-radius: 4px !important;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  box-shadow: none !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding-bottom: 0px !important;
}

/*  classes for qa enviroment */

.css-xubunq.Mui-selected {
  background-color: #e6e7f3 !important;
  height: 27px;
  border-radius: 4px !important;
}

.MuiMenuItem-root {
  height: 27px;
}

.css-xubunq {
  background-color: #e6e7f3 !important;
  margin-bottom: 4px !important;
  border-radius: 4px !important;
}

.css-177ic5c {
  box-shadow: none !important;
}

.css-r8u8y9 {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.delete-attention:hover {
  cursor: pointer;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px !important;
}

.select-icon:hover {
  cursor: pointer;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d6d8e8;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d6d8e8;
}

::-webkit-color-swatch {
  /* border-radius: 100%; */
  padding: 24;
  border: 0;
  /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); */
}

.role-close-vl:hover {
  cursor: pointer;
}

.colorPicker-vl {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
  /* width: 42px; */
  height: 32px;
  cursor: pointer;
}

.delete-attention-vl:hover {
  cursor: pointer;
}

.inactive:hover {
  background: #ffffff !important;
  border: 1px solid #ea345f !important;
  color: #ea345f !important;
}

/* .MuiDialog-paperFullScreen {
  border-top-left-radius: 10px !important;
  height: 6 0% !important;
  position: fixed !important;
  bottom: 0px !important;
  width: 90% !important;
  border-top-right-radius: 10px !important;
} */
